import {
  Alert,
  Box,
  Button,
  Collapse,
  FormControl,
  MenuItem,
  TextField,
} from "@mui/material";
import "./App.css";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import { PDFDocument } from "pdf-lib";
import template from "./pdf/rc.pdf";

function App() {
  const queryParameters = new URLSearchParams(window.location.search);
  const name = queryParameters.get("name");
  const fontSize = 14;
  const [formData, setFormData] = useState({
    Nome: name,
    Ral: "",
    TechStack: "",
    Ruolo: "",
    Seniority: "",
    Goal: "",
    Inglese: "",
    NomeSales: "",
    NomeSetter: "",
  });

  const [errorText, setErrorText] = useState("");
  const [copied, setCopied] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState("");

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth();
  let year = date.getFullYear();
  let currentDate = `${day}/${month}/${year}`;

  function fillTemplate() {
    setEmailTemplate(
      `Gentile ${formData.Nome},\nCome promesso, ti allego il report dettagliato sui risultati che puoi aspettarti nel  mercato europeo.\nQuesto report è stato preparato con cura e tiene conto di vari fattori, inclusi trend attuali, richieste del mercato e analisi delle competenze richieste.\nSe hai domande o hai bisogno di ulteriori dettagli su qualche aspetto del report, sono a tua disposizione per una discussione più approfondita. La tua soddisfazione è la nostra priorità, e lavoreremo con impegno per garantire che le tue aspettative siano pienamente soddisfatte.\nGrazie ancora per la tua fiducia nel nostro team. Non vedo l'ora di sapere come è andato il colloquio con ${formData.NomeSales} e di discutere delle prossime mosse strategiche per migliorare la tua situazione lavorativa.\nCordiali saluti,\n${formData.NomeSetter}`
    );
  }

  function cleanFields(e) {
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  }

  async function fillForm() {
    setErrorText("");
    const formPdfBytes = await fetch(template).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(formPdfBytes);
    const form = pdfDoc.getForm();

    function getAndFormatField(fieldname) {
      let formfield = form.getTextField(fieldname);
      formfield.setFontSize(fontSize);
      return formfield;
    }

    const nameField = getAndFormatField("nome");
    const nameField2 = getAndFormatField("nome2");
    const dataField = getAndFormatField("data");
    const ralField = getAndFormatField("ralit");
    const techStacktField = getAndFormatField("techstack");
    const roleField = getAndFormatField("ruolo");
    const seniorityField = getAndFormatField("seniority");
    const englishField = getAndFormatField("inglese");
    const raleuField = getAndFormatField("raleu");
    const remoteField = getAndFormatField("remoto");
    const aumentoField = getAndFormatField("aumento");
    const obiettivoField = getAndFormatField("obiettivo");
    nameField.setText(formData.Nome);
    nameField2.setText(formData.Nome);
    dataField.setText(currentDate);
    remoteField.setText("Remoto");
    aumentoField.setText("15-20% ogni 12/18 mesi");
    ralField.setText(formData.Ral);
    raleuField.setText(getEuropeanRal(formData.Seniority));
    techStacktField.setText(formData.TechStack);
    roleField.setText(formData.Ruolo);
    seniorityField.setText(formData.Seniority);
    englishField.setText(formData.Inglese);
    obiettivoField.setText(`${formData.Nome} è quello di ${formData.Goal}`);
    form.flatten();
    const pdfBytes = await pdfDoc.save();
    const url = window.URL.createObjectURL(new Blob([pdfBytes]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${formData.Nome}_Report.pdf`);
    //TEMPLATE EMAIL

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
    fillTemplate();
    navigator.clipboard.writeText(emailTemplate);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    await fillForm();
  };

  function getEuropeanRal(seniority) {
    switch (seniority) {
      case "Entry Level":
        return "€35k - €40k";
      case "Junior":
        return "€40k - €45k";
      case "Middle":
        return "€50k - €60k";
      case "Senior":
        return "€65k +";
      case "Freelancer":
        return "€65k +";
      default:
        return "€50k - €60k";
    }
  }
  return (
    <div className="App">
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1}>
          <Grid
            item
            xs={6}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "100vh" }}
          >
            <FormControl>
              {Object.keys(formData).map((field) => {
                return field === "Seniority" ||
                  field === "Goal" ||
                  field === "Ral" ||
                  field === "NomeSales" ||
                  field === "NomeSetter" ? (
                  ""
                ) : (
                  <TextField
                    defaultValue={field == "Nome" ? name : ""}
                    sx={{ mt: 1 }}
                    key={field}
                    label={field}
                    onChange={(e) => {
                      setFormData({ ...formData, [field]: e.target.value });
                    }}
                  />
                );
              })}
              <TextField
                sx={{ mt: 1 }}
                select
                id="senioritySelect"
                value={formData.Seniority}
                label="Seniority"
                onChange={(e) =>
                  setFormData({ ...formData, Seniority: e.target.value })
                }
              >
                <MenuItem value={"Entry Level"}>Entry Level</MenuItem>
                <MenuItem value={"Junior"}>Junior</MenuItem>
                <MenuItem value={"Mid"}>Mid</MenuItem>
                <MenuItem value={"Senior"}>Senior</MenuItem>
                <MenuItem value={"Tech Lead"}>Tech Lead</MenuItem>
                <MenuItem value={"Freelancer"}>Freelancer</MenuItem>
              </TextField>
              <TextField
                sx={{ mt: 1 }}
                select
                id="ralSelect"
                value={formData.Ral}
                label="Ral"
                onChange={(e) =>
                  setFormData({ ...formData, Ral: e.target.value })
                }
              >
                <MenuItem value={"Disoccupato"}>Disoccupato</MenuItem>
                <MenuItem value={"0 - 25k€"}>0 - 25k€</MenuItem>
                <MenuItem value={"25k€ - 30k€"}>25k€ - 30k€</MenuItem>
                <MenuItem value={"30k€ - 35k€"}>30k€ - 35k€</MenuItem>
                <MenuItem value={"35k€ - 40k€"}>35k€ - 40k€</MenuItem>
                <MenuItem value={"40k€ - 45k€"}>40k€ - 45k€</MenuItem>
                <MenuItem value={"45k€+"}>45k€ +</MenuItem>
              </TextField>
              <TextField
                sx={{ mt: 1 }}
                select
                id="goalSelect"
                value={formData.Goal}
                label="Goal"
                onChange={(e) =>
                  setFormData({ ...formData, Goal: e.target.value })
                }
              >
                <MenuItem value={"guadagnare di più."}>
                  Guadagnare di più
                </MenuItem>
                <MenuItem value={"trovare lavoro."}>Trovare lavoro</MenuItem>
                <MenuItem value={"lavorare in un ambiente più stimolante."}>
                  Lavorare in un ambiente più stimolante
                </MenuItem>
                <MenuItem value={"migliorare il Work-Life balance."}>
                  Migliorare il Work-Life balance
                </MenuItem>
              </TextField>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={6}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "100vh" }}
          >
            <TextField
              sx={{ mt: 1, width: "160px" }}
              select
              id="salesSelect"
              value={formData.NomeSales}
              label="Sales"
              onChange={(e) =>
                setFormData({ ...formData, NomeSales: e.target.value })
              }
            >
              <MenuItem value={"Leonardo"}>Leonardo</MenuItem>
              <MenuItem value={"Riccardo"}>Riccardo</MenuItem>
              <MenuItem value={"Elia"}>Elia</MenuItem>
            </TextField>
            <TextField
              sx={{ mt: 1, width: "160px" }}
              select
              id="setterSelect"
              value={formData.NomeSetter}
              label="Setter"
              onChange={(e) =>
                setFormData({ ...formData, NomeSetter: e.target.value })
              }
            >
              <MenuItem value={"Angelica"}>Angelica</MenuItem>
              <MenuItem value={"Benedetta"}>Benedetta</MenuItem>
            </TextField>
            <Collapse in={errorText.length > 0}>
              <Alert severity="error">{errorText}</Alert>
            </Collapse>
            <Button
              sx={{ my: 1 }}
              disabled={!formData.Nome || formData.Ral <= 0}
              onClick={(e) => onSubmit(e)}
              variant="contained"
            >
              Genera PDF E COPIA TEMPLATE
            </Button>
            <Button
              sx={{ mb: 1 }}
              onClick={(e) => cleanFields(e)}
              variant="contained"
              color="error"
            >
              PULISCI CAMPI
            </Button>

            <Collapse in={copied}>
              <Alert> Copiato!</Alert>
            </Collapse>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default App;
